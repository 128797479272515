import type { FC } from "react";
import React, { useEffect, useState } from "react";
import type FunnelKey from "src/constants/funnelKey";
import type Path from "src/constants/path";
import SignupCubit from "src/state/SignupCubit/SignupCubit";
import { SignupStateSuggestionRegister } from "src/state/SignupCubit/SignupState";
import { useBloc as useBloc2 } from "@blac/react";
import type { TranslationKey } from "src/types/translationKey";
import LoginForm from "src/ui/components/LoginForm/LoginForm";
import ResetPasswordForm from "src/ui/components/ResetPasswordForm/ResetPasswordForm";
import { Pad } from "src/ui/styled/Pad";
import RegisterForm from "../RegisterForm/RegisterForm";

type AuthSteps = "login" | "recover" | "register";
const FunnelAuthStep: FC<{
  onSuccess?: () => void;
  registerTitle?: TranslationKey;
  showMoneyBackGuarantee?: boolean;
  buttonLabel?: TranslationKey;
  initialStep?: AuthSteps;
  controlled?: boolean;
  registerLink?: Path;
  scopeKey?: FunnelKey;
}> = ({ onSuccess = (): void => {}, ...props }) => {
  const [accountStep, setAccountStep] = useState<AuthSteps>(
    props.initialStep ?? "register"
  );

  const [signup] = useBloc2(SignupCubit);

  useEffect(() => {
    if (signup instanceof SignupStateSuggestionRegister) {
      return setAccountStep("register");
    }
  }, [signup.name]);

  return (
    <Pad className="wrap-center">
      {accountStep === "register" && (
        <RegisterForm
          onSuccess={onSuccess}
          loginLink={(): void => setAccountStep("login")}
          title={props.registerTitle}
          buttonLabel={props.buttonLabel}
          scopeKey={props.scopeKey}
        />
      )}

      {accountStep === "login" && (
        <LoginForm
          onSuccess={onSuccess}
          resetPasswordLink={(): void => setAccountStep("recover")}
          registerLink={
            props.registerLink ?? ((): void => setAccountStep("register"))
          }
        />
      )}

      {accountStep === "recover" && (
        <ResetPasswordForm
          onSuccess={(): void => setAccountStep("login")}
          loginLink={(): void => setAccountStep("login")}
        />
      )}
    </Pad>
  );
};

export default FunnelAuthStep;
